import { actions } from "astro:actions";
import { useState, type FormEventHandler } from "react";
import { cn } from "../../lib/utils";
import { Turnstile } from "@marsidev/react-turnstile";

export default function ContactForm({ TURNSTILE_SITE_KEY }: { TURNSTILE_SITE_KEY: string }) {
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const submit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        const formData = new FormData(form);

        setError(null);
        setSubmitting(true);

        const res = await actions.message(formData);

        setSubmitting(false);

        if (res.error) {
            setError("Une erreur est survenue, veuillez réessayer plus tard.");
            return;
        }

        if (res.data) {
            setSuccess(true);
            form.reset();
        }
    };

    return (
        <form method="POST" className="relative" onSubmit={submit}>
            <div
                className={cn(
                    "absolute top-0 left-0 w-full text-white text-center h-full justify-center pt-32 bg-neutral-900 z-10 rounded-md",
                    success ? "block" : "hidden",
                )}
            >
                <h4 className="font-semibold text-candlelight-300">Merci pour votre message !</h4>
                <p className="mt-1 text-neutral-400">Nous vous répondrons dans les plus brefs délais.</p>
            </div>
            <fieldset disabled={submitting}>
                <div className="space-y-4">
                    <div className="relative">
                        <input
                            type="text"
                            name="name"
                            className="peer p-4 block w-full bg-neutral-800 border-transparent rounded-lg text-sm text-white placeholder:text-transparent focus:outline-none focus:ring-0 focus:border-transparent disabled:opacity-50 disabled:pointer-events-none focus:pt-6 focus:pb-2 [&:not(:placeholder-shown)]:pt-6 [&:not(:placeholder-shown)]:pb-2 autofill:pt-6 autofill:pb-2"
                            placeholder="Nom"
                            required
                        />
                        <label
                            htmlFor="hs-tac-input-name"
                            className="absolute top-0 start-0 p-4 h-full text-neutral-400 text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent peer-disabled:opacity-50 peer-disabled:pointer-events-none
                  peer-focus:text-xs
                  peer-focus:-translate-y-1.5
                  peer-focus:text-neutral-400
                  peer-[:not(:placeholder-shown)]:text-xs
                  peer-[:not(:placeholder-shown)]:-translate-y-1.5
                  peer-[:not(:placeholder-shown)]:text-neutral-400"
                        >
                            Nom <span className="text-xs">*</span>
                        </label>
                    </div>

                    <div className="relative">
                        <input
                            type="email"
                            name="email"
                            className="peer p-4 block w-full bg-neutral-800 border-transparent rounded-lg text-sm text-white placeholder:text-transparent focus:outline-none focus:ring-0 focus:border-transparent disabled:opacity-50 disabled:pointer-events-none
                focus:pt-6
                focus:pb-2
                [&:not(:placeholder-shown)]:pt-6
                [&:not(:placeholder-shown)]:pb-2
                autofill:pt-6
                autofill:pb-2"
                            placeholder="Email"
                            required
                        />
                        <label
                            htmlFor="hs-tac-input-email"
                            className="absolute top-0 start-0 p-4 h-full text-neutral-400 text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent peer-disabled:opacity-50 peer-disabled:pointer-events-none
                  peer-focus:text-xs
                  peer-focus:-translate-y-1.5
                  peer-focus:text-neutral-400
                  peer-[:not(:placeholder-shown)]:text-xs
                  peer-[:not(:placeholder-shown)]:-translate-y-1.5
                  peer-[:not(:placeholder-shown)]:text-neutral-400"
                        >
                            Email <span className="text-xs">*</span>
                        </label>
                    </div>

                    <div className="relative">
                        <input
                            type="text"
                            name="company"
                            className="peer p-4 block w-full bg-neutral-800 border-transparent rounded-lg text-sm text-white placeholder:text-transparent focus:outline-none focus:ring-0 focus:border-transparent disabled:opacity-50 disabled:pointer-events-none
                focus:pt-6
                focus:pb-2
                [&:not(:placeholder-shown)]:pt-6
                [&:not(:placeholder-shown)]:pb-2
                autofill:pt-6
                autofill:pb-2"
                            placeholder="Entreprise"
                        />
                        <label
                            htmlFor="hs-tac-input-company"
                            className="absolute top-0 start-0 p-4 h-full text-neutral-400 text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent peer-disabled:opacity-50 peer-disabled:pointer-events-none
                  peer-focus:text-xs
                  peer-focus:-translate-y-1.5
                  peer-focus:text-neutral-400
                  peer-[:not(:placeholder-shown)]:text-xs
                  peer-[:not(:placeholder-shown)]:-translate-y-1.5
                  peer-[:not(:placeholder-shown)]:text-neutral-400"
                        >
                            Entreprise
                        </label>
                    </div>

                    <div className="relative">
                        <input
                            type="text"
                            name="phone"
                            className="peer p-4 block w-full bg-neutral-800 border-transparent rounded-lg text-sm text-white placeholder:text-transparent focus:outline-none focus:ring-0 focus:border-transparent disabled:opacity-50 disabled:pointer-events-none
                focus:pt-6
                focus:pb-2
                [&:not(:placeholder-shown)]:pt-6
                [&:not(:placeholder-shown)]:pb-2
                autofill:pt-6
                autofill:pb-2"
                            placeholder="Phone"
                        />
                        <label
                            htmlFor="hs-tac-input-phone"
                            className="absolute top-0 start-0 p-4 h-full text-neutral-400 text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent peer-disabled:opacity-50 peer-disabled:pointer-events-none
                  peer-focus:text-xs
                  peer-focus:-translate-y-1.5
                  peer-focus:text-neutral-400
                  peer-[:not(:placeholder-shown)]:text-xs
                  peer-[:not(:placeholder-shown)]:-translate-y-1.5
                  peer-[:not(:placeholder-shown)]:text-neutral-400"
                        >
                            Téléphone
                        </label>
                    </div>

                    <div className="relative border-transparent rounded-lg flex flex-col-reverse">
                        <textarea
                            name="message"
                            rows={3}
                            className="peer p-4 block w-full bg-neutral-800 border-transparent rounded-b-lg text-sm text-white placeholder:text-transparent focus:outline-none focus:ring-0 focus:border-transparent disabled:opacity-50 disabled:pointer-events-none
                [&:not(:placeholder-shown)]:pt-0
                [&:not(:placeholder-shown)]:pb-2"
                            required
                        ></textarea>
                        <label
                            htmlFor="message"
                            className={cn(
                                "p-4 pb-0 h-full text-neutral-400 bg-neutral-800 rounded-t-lg text-sm pointer-events-none transition ease-in-out duration-100 border border-transparent",
                                submitting ? "opacity-50 pointer-events-none" : "",
                            )}
                        >
                            Parlez nous de votre projet<span className="text-xs">*</span>
                        </label>
                    </div>
                </div>

                <div className="mt-2">
                    <div className="flex items-start justify-between mt-2">
                        <p className="text-xs text-neutral-500">
                            <span className="text-xs">*</span> champs requis
                        </p>

                        <div className="flex items-end flex-col">
                            <button
                                id="contact-form-submit"
                                type="submit"
                                className="disabled:opacity-50 group disabled:pointer-events-none group inline-flex items-center gap-x-2 py-2 px-3 bg-candlelight-300 hover:bg-candlelight-300/90 font-medium text-sm text-neutral-800 rounded-full focus:outline-none my-4"
                            >
                                {submitting ? "Envoi en cours..." : "Envoyer"}
                                {submitting ? (
                                    <svg
                                        className="lucide size-4 flex-shrink-0 lucide-loader-circle animate-spin"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                                    </svg>
                                ) : (
                                    <svg
                                        className="flex-shrink-0 size-4 transition group-hover:translate-x-0.5 group-focus:translate-x-0.5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M5 12h14"></path>
                                        <path d="m12 5 7 7-7 7"></path>
                                    </svg>
                                )}
                            </button>

                            <Turnstile siteKey={TURNSTILE_SITE_KEY} />
                            {error && <p className="text-xs text-red-500">{error}</p>}
                        </div>
                    </div>
                    <p className="text-xs text-neutral-500">
                        En soumettant ce formulaire, vous acceptez nos{" "}
                        <a href="/politique-de-confidentialite" target="_blank" className="text-candlelight-300">
                            politiques de confidentialité
                        </a>{" "}
                        et consentez au traitement de vos données personnelles conformément à celles-ci.
                    </p>
                </div>
            </fieldset>
        </form>
    );
}
